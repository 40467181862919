<template>
  <div>
    <line-chart v-if="chartData" :chart-data="chartData" :options="chartOptions" />
    <div class="relativeContainer">
      <ClickIcon
        icon="left"
        @click="$emit('leftClick')"
        title="View Older"
        class="left"
        tooltipPosition="is-right"
        :disabled="leftDisabled"
      />
      <ClickIcon
        icon="right"
        @click="$emit('rightClick')"
        title="View Newer"
        class="right"
        tooltipPosition="is-left"
        :disabled="rightDisabled"
      />
    </div>
  </div>
</template>

<script>
import ClickIcon from "@/components/ClickIcon.vue";
import LineChart from "@/components/Charts/LineChart";

export default {
  components: { LineChart, ClickIcon },
  props: {
    chartData: { type: Object },
    chartOptions: { type: Object },
    leftDisabled: { type: Boolean },
    rightDisabled: { type: Boolean }
  }
};
</script>

<style>
.left {
  position: absolute;
  bottom: 3px;
  left: 3px;
}
.right {
  position: absolute;
  bottom: 3px;
  right: 3px;
}
</style>
