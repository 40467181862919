<template>
  <CollapseCard
    :title="manualHandling.HandlingLookup[handlingInfo.type]"
    :name="'handling' + handlingInfo.type"
    :open.sync="handlingInfo.isOpen"
  >
    <ValidationProvider rules="required" v-slot="{ errors }">
      <b-field label="Capability" horizontal :type="{ 'is-danger': errors[0] }" :message="errors">
        <b-dropdown v-model="handlingInfo.capability" required :disabled="readonly" class="capabilityDropDown">
          <template #trigger="{ active }" v-if="handlingInfo.capability">
            <div class="media">
              <img class="media-left" :src="require('@/assets/manualHandling/' + handlingInfo.capability + '.png')" />
              <div class="media-content">
                {{ manualHandling.CapabilityLookup[handlingInfo.capability] }}
                <b-icon class="" :icon="active ? 'menu-up' : 'menu-down'" />
              </div>
            </div>
          </template>
          <template #trigger="{ active }" v-else>
            <b-button label="<Select Capability>" :icon-right="active ? 'menu-up' : 'menu-down'" />
          </template>
          <b-dropdown-item v-for="item in manualHandling.CapabilitySelect" :key="item.id" :value="item.id">
            <div class="media">
              <img class="media-left" :src="require(`@/assets/manualHandling/${item.id}.png`)" />
              <div class="media-content">
                <h3>{{ item.value }}</h3>
                <small>{{ manualHandling.CapabilityExtra[item.id] }}</small>
              </div>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </b-field>
    </ValidationProvider>

    <NotesField
      notesRef="movingInstructions"
      v-model="handlingInfo.instructions"
      :readonly="readonly"
      label="Instructions"
      :hasCounter="false"
      required
    />

    <NotesIconField notesRef="movingNotes" v-model="handlingInfo.notes" :readonly="readonly" type="textarea" />
    <NotesIconField
      notesRef="movingPhysio"
      v-model="handlingInfo.physio"
      :readonly="readonly"
      type="textarea"
      label="Physiotherapy Special Requirements"
    />
  </CollapseCard>
</template>

<script>
import CollapseCard from "@/components/CollapseCard.vue";
import NotesField from "@/components/NotesField.vue";
import NotesIconField from "@/components/NotesIconField.vue";

import ManualHandling from "@/enums/manualHandling";

export default {
  props: {
    handlingInfoProp: { type: Object },
    readonly: { type: Boolean }
  },
  components: { CollapseCard, NotesField, NotesIconField },
  data() {
    return {
      manualHandling: ManualHandling,
      handlingInfo: {
        type: null,
        capability: null,
        instructions: null,
        notes: null,
        physio: null,
        isOpen: true
      }
    };
  },
  watch: {
    handlingInfoProp: {
      deep: true,
      immediate: true,
      handler(v) {
        this.handlingInfo = v;
      }
    },

    handlingInfo: {
      deep: true,
      handler(v) {
        this.$emit("update:handlingInfo", v);
      }
    }
  }
};
</script>

<style>
.capabilityDropDown img {
  max-width: 45px;
}
.capabilityDropDown a.is-active img {
  background-color: white;
  padding: 2px;
}
.capabilityDropDown h3 {
  font-size: 1em;
}
</style>
