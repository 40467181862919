<template>
  <div>
    <ValidationObserver v-if="dialog" ref="observer">
      <form @submit.prevent="handleSubmitWithErrorScroll">
        <div class="modal-card" :class="{ 'modal-card-wide': isWideDialog }">
          <header class="modal-card-head">
            <p class="modal-card-title">
              <b-icon v-if="icon" :icon="Icons[icon] || icon" />
              <span v-if="customIcon" class="icon has-text-primary is-default"> <img :src="'/img/icons/' + customIcon" /> </span>
              {{ formTitle }}
            </p>
            <button type="button" class="delete" @click="close" />
          </header>
          <section class="modal-card-body">
            <slot />
          </section>

          <footer class="modal-card-foot">
            <nav class="level is-mobile">
              <div class="level-left">
                <slot name="extraLeftButtons" :buttonProps="{ isSaving, close, checkIsDirty, setNotDirty }" />
              </div>
              <div class="level-right">
                <button class="level-item button" type="button" @click="close">Cancel</button>
                <b-button
                  v-if="!disabled && canSave"
                  :disabled="saveDisabled"
                  class="level-item button is-primary"
                  :loading="isSaving"
                  native-type="submit"
                  >{{ saveText }}</b-button
                >
                <!-- Hidden submit button to submit from method -->
                <button ref="submitBtn" native-type="submit" style="display: none">Submit</button>
                <slot name="extraButtons" :buttonProps="{ isSaving, close }" />
              </div>
            </nav>
          </footer>
        </div>
      </form>
    </ValidationObserver>
    <XyeaForm
      v-else
      :formTitle="formTitle"
      :icon="icon"
      @submit="submit"
      :isSaving="isSaving"
      :saveButton="canSave"
      :saveText="saveText"
      :saveDisabled="saveDisabled"
      :backRoute="backRoute"
      ref="fullForm"
    >
      <slot />
    </XyeaForm>
    <LoadingData v-if="isLoading" />
  </div>
</template>

<script>
import XyeaForm from "@/components/XyeaForm";
import LoadingData from "@/components/LoadingData.vue";
import CheckDirty from "@/mixins/checkDirty";
import IconsMixin from "@/mixins/iconsMixin";
import formMixin from "@/mixins/formMixin.js";

export default {
  mixins: [CheckDirty, IconsMixin, formMixin],
  components: {
    XyeaForm,
    LoadingData
  },
  emits: [
    // These events can be mapped to base functions in checkDirty.js or override as needed,
    // but for simple forms there are not needed:
    //   mapServerData should be set if getData is called, and the checkDirty should be done in the parent component then
    //   but most forms / dialogs that use the data passed in, it gets set on mounted and so local dirty check is enough
    "mapServerData", // after getData, should call setNotDirty after updating form
    "dataSaved" // after add or update, should call setNotDirty as a minimum so route change does not trigger warning
  ],
  props: {
    id: { type: [String, Number], default: null, require: true },
    id2: { type: [String, Number], default: null, require: false }, // eg medical history type
    id3: { type: [String, Number], default: null, require: false }, // eg medical history - assessment Id
    icon: { type: String },
    customIcon: { type: String },
    url: { type: String, require: true }, // With trailing Slash
    backRoute: { type: String },
    entityName: { type: String, require: true },
    form: { type: [Object, Array], require: true },
    formPostData: { type: Function, require: false, default: () => null }, // custom func to get data to post on save
    canSave: { type: Boolean, default: true },
    canSaveNewNotDirty: { type: Boolean }, // Normally Save is disabled is not dirty, but sometimes we need it enabled anyway
    saveBtnDisabled: { type: Boolean },
    loadOnOpen: { type: Boolean, default: true },
    disabled: { type: Boolean },
    validForm: { type: Boolean, default: true }, // For fields outside HTML validation, eg phones
    dialog: { type: Boolean }, //  if form is popup dialog rather than full page
    isWideDialog: { type: Boolean },
    customTitle: { type: String },
    useCustomSave: { type: Boolean }, // use custom save func, data returned in save event, rather than posted to url
    closeOnSave: { type: Boolean, default: true } // normal we savea and close, but with customeSave we may want finer control
  },
  data() {
    return {
      isLoading: false,
      isSaving: false,
      locationNameReadOnly: null,
      stayAfterSubmit: false
    };
  },
  computed: {
    formTitle() {
      return this.customTitle || (this.disabled ? "View " : this.isEdit ? "Edit " : "New ") + this.entityName;
    },
    isEdit() {
      return this.id > 0;
    },
    saveText() {
      return this.id > 0 ? "Save Changes" : "Save New";
    },
    saveDisabled() {
      return this.disabled || this.saveBtnDisabled || !this.canSave || (!this.isDirty() && (!this.canSaveNewNotDirty || this.isEdit));
    }
  },
  watch: {
    id: {
      immediate: true,
      handler(newValue) {
        if (newValue && this.loadOnOpen) this.getData();
      }
    },
    // This is another way to set not dirty in this control, when parent sets the form.notDirty property
    "form.notDirty": {
      handler(v) {
        if (v) {
          this.setNotDirty();
          this.form.notDirty = false;
        }
      }
    }
  },
  methods: {
    // option to pass in other event if can be closed
    close(closeEvent) {
      if (typeof closeEvent !== "string") closeEvent = "close";
      this.checkIsDirty(null, null, () => this.$emit(closeEvent));
    },
    submitFormAndStay() {
      if (!this?._uid) return;
      this.stayAfterSubmit = true;
      if (this.dialog) this.$refs.submitBtn?.click();
      else this.$refs.fullForm?.$refs?.submitBtn?.click();
    },
    submitForm() {
      this.$refs.submitBtn.click();
    },

    async submit() {
      if (!this.validForm) return;

      this.isSaving = true;
      const data = this.formPostData() || this.form;

      // useCustomSave option to return data in save event rather than submit here
      if (this.useCustomSave) {
        this.$emit("save", data);
        if (this.closeOnSave) this.closeAfterSave();
        return;
      }

      // Url is made of what is passed in, with trailing slash
      // then the id and optionally the id2
      var url = this.url + this.id;
      if (this.id2 !== null) {
        url += "/" + this.id2;
        if (this.id3) url += "/" + this.id3; // don't send 0, will be treated as a null
      }

      if (this.isEdit) {
        await this.$http
          .put(url, data)
          .then(r => {
            this.$emit("dataSaved"); // results in setNotDirty being called in parent component, but we call it here also so don't have to use dataSaved event
            this.$emit("update", r?.data);
            if (this.stayAfterSubmit) {
              this.gotData(r.data, true);
            } else {
              this.closeAfterSave();
            }
            this.$buefy.snackbar.open({ message: this.entityName + " updated", queue: false });
          })
          .catch(e => this.$alerts.showErrorAlert(e, "Error updating " + this.entityName, this.dialog ? this.close : null));
      } else {
        await this.$http
          .post(this.url, data)
          .then(r => {
            this.$emit("dataSaved"); // results in setNotDirty being called
            this.$emit("add", r?.data);
            if (this.stayAfterSubmit) {
              this.gotData(r.data, true);
            } else {
              this.closeAfterSave();
            }
            this.$buefy.snackbar.open({ message: "New " + this.entityName + " added", queue: false });
          })
          .catch(e => this.$alerts.showErrorAlert(e, "Error adding new " + this.entityName, this.dialog ? this.close : null));
      }
      this.isSaving = false;
      this.stayAfterSubmit = false;
    },

    closeAfterSave() {
      this.setNotDirty(true);
      if (this.dialog) this.close();
      else if (this.backRoute) this.$router.push({ name: this.backRoute });
      else if (this.$store.state.previousPage) this.$router.back();
      else this.$router.push(this.$store.getters.defaultRoute);
    },

    getData() {
      this.isLoading = true;
      var url = this.url + this.id;
      if (this.id2 !== null) {
        url += "/" + this.id2;
        if (this.id3) url += "/" + this.id3; // don't send 0, will be treated as a null
      }

      this.$http
        .get(url)
        .then(r => this.gotData(r.data))
        .catch(e => this.$alerts.showErrorAlert(e, "Error loading " + this.entityName))
        .finally(() => (this.isLoading = false));
    },
    gotData(data, afterSave) {
      this.$emit("mapServerData", data); // this should call setNotDirty on the parent component after updating the form
      this.setNotDirty(afterSave); // this sets notDirty on the form
    }
  },
  mounted() {
    this.$root.$on("saveFirst", this.submitFormAndStay);
  }
};
</script>
