<template>
  <AssessmentForm
    :assessmentDate="form.assessmentDate"
    :assessmentId="form.assessmentId"
    :assessmentType="assessmentType"
    :form="form"
    :readonly="readonly"
    :saveDisabled="saveDisabled"
    @setResidentData="setResidentData"
    @setAssessmentDate="setAssessmentDate"
    @mapServerData="mapServerData"
    @dataSaved="dataSaved"
    :showRating="false"
  >
    <div class="columns">
      <div class="column">
        <WeightComponent :form="form" :readonly="readonly" />
      </div>
      <div class="column"></div>
    </div>
    <ManualHandlingInfo :handlingInfoProp="movingHandlingInfo" @update:handlingInfo="updateMovingHandlingInfo" :readonly="readonly" />
    <ManualHandlingInfo :handlingInfoProp="inOutBedHandlingInfo" @update:handlingInfo="updateInOutBedHandlingInfo" :readonly="readonly" />
    <ManualHandlingInfo :handlingInfoProp="transferHandlingInfo" @update:handlingInfo="updateTransferHandlingInfo" :readonly="readonly" />
    <ManualHandlingInfo :handlingInfoProp="standSitHandlingInfo" @update:handlingInfo="updateStandSitHandlingInfo" :readonly="readonly" />
    <ManualHandlingInfo :handlingInfoProp="mobilityHandlingInfo" @update:handlingInfo="updateMobilityHandlingInfo" :readonly="readonly" />
  </AssessmentForm>
</template>

<script>
import AssessmentForm from "@/views/assessments/AssessmentForm";
import ManualHandlingInfo from "@/components/assessments/ManualHandlingInfo";
import WeightComponent from "@/components/assessments/WeightComponent.vue";

import AssessmentType from "@/enums/assessmentType";
import ManualHandling from "@/enums/manualHandling";
import assessmentMixin from "@/mixins/assessmentMixin";

export default {
  mixins: [assessmentMixin],
  components: { AssessmentForm, ManualHandlingInfo, WeightComponent },
  data() {
    return {
      assessmentType: AssessmentType.Enum.ManualHandling,
      manualHandling: ManualHandling,
      form: this.getClearFormObject()
    };
  },

  computed: {
    movingHandlingInfo() {
      return {
        type: ManualHandling.HandlingEnum.Moving,
        isOpen: true,
        capability: this.form.movingCapability,
        instructions: this.form.movingInstructions,
        notes: this.form.movingNotes,
        physio: this.form.movingPhysio
      };
    },
    inOutBedHandlingInfo() {
      return {
        type: ManualHandling.HandlingEnum.InOutBed,
        isOpen: true,
        capability: this.form.inOutBedCapability,
        instructions: this.form.inOutBedInstructions,
        notes: this.form.inOutBedNotes,
        physio: this.form.inOutBedPhysio
      };
    },
    transferHandlingInfo() {
      return {
        type: ManualHandling.HandlingEnum.Transfer,
        isOpen: true,
        capability: this.form.transferCapability,
        instructions: this.form.transferInstructions,
        notes: this.form.transferNotes,
        physio: this.form.transferPhysio
      };
    },
    standSitHandlingInfo() {
      return {
        type: ManualHandling.HandlingEnum.StandSit,
        isOpen: true,
        capability: this.form.standSitCapability,
        instructions: this.form.standSitInstructions,
        notes: this.form.standSitNotes,
        physio: this.form.standSitPhysio
      };
    },
    mobilityHandlingInfo() {
      return {
        type: ManualHandling.HandlingEnum.Mobility,
        isOpen: true,
        capability: this.form.mobilityCapability,
        instructions: this.form.mobilityInstructions,
        notes: this.form.mobilityNotes,
        physio: this.form.mobilityPhysio
      };
    }
  },

  methods: {
    getClearFormObject() {
      return {
        assessmentId: this.id,
        residentId: this.residentId,
        assessmentDate: new Date(),
        gender: null,
        age: null,

        weight: null,
        weightId: null,
        weightDate: null,
        previousWeightId: null,
        previousWeight: null,
        previousWeightDate: null,

        movingCapability: null,
        movingInstructions: null,
        movingNotes: null,
        movingPhysio: null,

        inOutBedCapability: null,
        inOutBedInstructions: null,
        inOutBedNotes: null,
        inOutBedPhysio: null,

        transferCapability: null,
        transferInstructions: null,
        transferNotes: null,
        transferPhysio: null,

        standSitCapability: null,
        standSitInstructions: null,
        standSitNotes: null,
        standSitPhysio: null,

        mobilityCapability: null,
        mobilityInstructions: null,
        mobilityNotes: null,
        mobilityPhysio: null
      };
    },
    updateMovingHandlingInfo(update) {
      this.form.movingCapability = update.capability;
      this.form.movingInstructions = update.instructions;
      this.form.movingNotes = update.notes;
      this.form.movingPhysio = update.physio;
    },
    updateInOutBedHandlingInfo(update) {
      this.form.inOutBedCapability = update.capability;
      this.form.inOutBedInstructions = update.instructions;
      this.form.inOutBedNotes = update.notes;
      this.form.inOutBedPhysio = update.physio;
    },
    updateTransferHandlingInfo(update) {
      this.form.transferCapability = update.capability;
      this.form.transferInstructions = update.instructions;
      this.form.transferNotes = update.notes;
      this.form.transferPhysio = update.physio;
    },
    updateStandSitHandlingInfo(update) {
      this.form.standSitCapability = update.capability;
      this.form.standSitInstructions = update.instructions;
      this.form.standSitNotes = update.notes;
      this.form.standSitPhysio = update.physio;
    },
    updateMobilityHandlingInfo(update) {
      this.form.mobilityCapability = update.capability;
      this.form.mobilityInstructions = update.instructions;
      this.form.mobilityNotes = update.notes;
      this.form.mobilityPhysio = update.physio;
    }
  }
};
</script>
