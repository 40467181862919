<template>
  <ValidationProvider :rules="weightRules" v-slot="{ errors }">
    <b-field
      label="Weight (kg)"
      horizontal
      class="label-long"
      :type="{ 'is-danger': errors[0] }"
      :message="recordedDate(form.weightDate, errors)"
    >
      <b-numberinput
        :value="form.weight"
        :controls="false"
        step="0.01"
        required
        disabled
        @click.native="editWeight"
        :loading="loadingWeight"
      />
      <b-icon v-if="!readonly" icon="plus-box-multiple" size="is-default" type="is-primary" class="clickable" @click.native="addWeight" />
    </b-field>
  </ValidationProvider>
</template>

<script>
import WeightDialog from "@/components/observations/WeightDialog";
import DatesMixin from "@/mixins/datesMixin";
import OrgConfig from "@/enums/orgconfig";
import { mapGetters } from "vuex";

export default {
  mixins: [DatesMixin],
  props: {
    form: { type: Object, default: () => {} },
    readonly: { type: Boolean, default: false }
  },
  data() {
    return {
      loadingWeight: false
    };
  },
  computed: {
    ...mapGetters("orgconfig", ["defaults"]),
    daysSinceWeight() {
      return this.daysSince(this.form.weightDate, this.form.assessmentDate);
    },
    weightRules() {
      return {
        weightRequired: true,
        weight: [20, 200],
        weightDays: [this.daysSinceWeight, this.defaults[OrgConfig.Enum.Obs_RecentWeightDays].value]
      };
    }
  },
  methods: {
    addWeight() {
      this.$buefy.modal.open({
        parent: this,
        component: WeightDialog,
        hasModalCard: true,
        events: { refresh: this.refreshWeighs }
      });
    },
    editWeight() {
      if (!this.form?.weightId) this.addWeight();
      else {
        this.$buefy.modal.open({
          parent: this,
          component: WeightDialog,
          hasModalCard: true,
          props: { observationId: this.form.weightId },
          events: { refresh: this.gotNewWeight }
        });
      }
    },
    gotNewWeight(data) {
      this.form.weight = data?.value;
      this.form.weightId = data?.observationId;
      this.form.weightDate = data?.obsDate;
    },
    gotPreviousWeight(data) {
      this.form.previousWeight = data?.value;
      this.form.previousWeightId = data?.observationId;
      this.form.previousWeightDate = data?.obsDate;
    },
    recordedDate(date, errors) {
      return errors[0] || (date ? "Recorded: " + this.shortFormatDate(date) : null);
    },
    refreshWeighs() {
      const residentId = this.$store.getters["resident/residentId"];
      if (!residentId) return;
      this.loadingWeight = false;
      this.$http
        .get(`observations?residentId=${residentId}&weightsOnly=true`)
        .then(r => {
          this.gotNewWeight(r.data.weight);
          this.gotPreviousWeight(r.data.previousForWeightLoss);
        })
        .catch(e => this.$alerts.showErrorAlert(e, "Error retrieving Weights"))
        .finally(() => {
          this.loadingWeight = false;
        });
    }
  },
  mounted() {
    // loads obs for new one only,
    if (!this.form.assessmentId) {
      const residentId = this.$store.getters["resident/residentId"];
      if (!residentId) return;
      this.loadingWeight = !this.form.weight;
      this.$http
        .get(`observations?residentId=${residentId}&bmiOnly=true`)
        .then(r => {
          if (!this.form.weight) this.gotNewWeight(r.data.weight);
          this.gotPreviousWeight(r.data.previousForWeightLoss);
          this.$emit("setNotDirty");
        })
        .catch(e => this.$alerts.showErrorAlert(e, "Error retrieving BMI Obs"))
        .finally(() => {
          this.loadingWeight = false;
        });
    }
  }
};
</script>
