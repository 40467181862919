<template>
  <section class="section is-main-section">
    <card-component :title="formTitle" :icon="icon" :class="cardclass">
      <ValidationObserver ref="observer">
        <form @submit.prevent="handleSubmitWithErrorScroll">
          <slot :submitForm="submitForm" />

          <div class="level has-text-centered scrollPaneFooter fullWidth is-mobile" :class="{ asideVisible: isAsideMobileExpanded }">
            <div class="level-item">
              <b-button @click="close">Close</b-button>
            </div>
            <div class="level-item" v-if="completeButton">
              <b-button type="is-primary" :loading="isSaving" @click="submit" :disabled="saveDisabled">{{ saveText }}</b-button>
            </div>
            <div class="level-item" v-if="saveButton">
              <b-button type="is-primary" :loading="isSaving" native-type="submit" :disabled="saveDisabled">{{ saveText }}</b-button>
            </div>
            <div class="level-item" v-if="completeButton">
              <b-button type="is-primary" :loading="isSaving" native-type="submit" :disabled="saveDisabled">Complete</b-button>
            </div>
            <!-- Hidden submit button to submit from method -->
            <button ref="submitBtn" native-type="submit" style="display: none">Submit</button>
          </div>
        </form>
      </ValidationObserver>
    </card-component>
  </section>
</template>

<script>
import CardComponent from "@/components/CardComponent";
import { mapState } from "vuex";
import formMixin from "@/mixins/formMixin.js";

export default {
  mixins: [formMixin],
  components: { CardComponent },
  props: {
    icon: { type: String, required: false },
    isSaving: { type: Boolean, required: false },
    cardclass: { type: String, required: false },
    formTitle: { type: String, required: true },
    saveButton: { type: Boolean, default: false },
    saveText: { type: String, default: "Save" },
    saveDisabled: { type: Boolean, default: false },
    backRoute: { type: String },
    completeButton: { type: Boolean } // Save button will not do form validation, but there will be addiational Complete button
  },
  computed: {
    ...mapState(["isAsideMobileExpanded"])
  },
  methods: {
    submit() {
      this.$emit("submit");
    },
    close() {
      if (this.backRoute) this.$router.push({ name: this.backRoute });
      else if (this.$store.state.previousPage) this.$router.back();
      else this.$router.push(this.$store.getters.defaultRoute);
    },
    submitForm() {
      this.$refs.submitBtn.click();
    }
  }
};
</script>
