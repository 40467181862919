// Basic functionality for Obs Dialog
import IconsMixin from "@/mixins/iconsMixin.js";
import { mapGetters } from "vuex";
import PermissionsEnum from "@/enums/permissions";

export default {
  mixins: [IconsMixin],
  props: {
    passedInOb: { type: Object },
    observationId: { type: Number },
    graphOnly: { type: Boolean },
    addPermission: { type: Number, default: PermissionsEnum.ObservationAdd },
    updatePermission: { type: Number, default: PermissionsEnum.ObservationUpdate }
  },
  data() {
    return {
      loadOnOpen: false,
      observation: this.getClearFormObject()
    };
  },
  computed: {
    ...mapGetters(["hasPermission"]),
    isEdit() {
      return !!this.observation.observationId;
    },
    readonly() {
      // check permision
      return this.isEdit && !this.hasPermission(this.updatePermission);
    },
    canAddNew() {
      return this.isEdit && this.hasPermission(this.addPermission);
    }
  },
  methods: {
    getClearFormObject() {
      return {
        observationId: 0,
        residentId: this.$store.getters["resident/residentId"],
        value: null,
        notes: null
      };
    },
    mapServerData(data) {
      this.observation = data; // map top level params
      // No need to call this.setNotDirty(); as this is view only
    },
    resetForm() {
      this.observation = this.getClearFormObject();
      // Todo: Set focus to value control, but can't get $refs inside the template to work
      // this.$refs.value.focus();
    }
  },
  mounted() {
    // note we copy the passed in details, rather than assign, as otherwise changes in the dialog would be immediately reflected back
    if (this.passedInOb != null) {
      this.observation = { ...this.passedInOb };
      this.observation.residentId = this.$store.getters["resident/residentId"];
    }

    // if observationId passed in, we fetch the details from database
    if (this.observationId) {
      this.loadOnOpen = true;
      this.observation = { observationId: this.observationId };
    }
  }
};
