<template>
  <ObsDialog
    :icon="Icons.obsWeight"
    url="obs_weight/"
    obName="Weight"
    unit="kg"
    :observation="observation"
    :readonly="readonly"
    :canAddNew="canAddNew"
    :graphOnly="graphOnly"
    @close="$emit('close')"
    @refresh="v => $emit('refresh', v)"
    @select="v => (this.observation = v)"
    @clear="resetForm"
    :loadOnOpen="loadOnOpen"
    @mapServerData="mapServerData"
  >
    <b-field label="Weight" horizontal>
      <div class="levelLeftMobile">
        <b-numberinput
          expanded
          v-model="observation.value"
          :controls="false"
          required="!readonly"
          :disabled="readonly"
          v-focus
          min="20"
          max="200"
        />
        <p class="control unitLabel">kg</p>
      </div>
    </b-field>
  </ObsDialog>
</template>

<script>
import ObsDialog from "@/components/observations/ObsDialog.vue";
import obsMixin from "@/mixins/obsMixin.js";

export default {
  mixins: [obsMixin],
  components: { ObsDialog }
};
</script>
