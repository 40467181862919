import { GetSelectLookup } from "./common";

const CapabilityEnum = {
  Hoist: 1,
  Requires2: 2,
  Requires1: 3,
  Slides: 4,
  Supervision: 5,
  Independent: 6
};

const CapabilityLookup = ["", "Hoist", "Requires 2", "Requires 1", "Slides", "Supervision", "Independent"];
const CapabilityExtra = ["", "", "Resident MUST be able to bear weight"];

const HandlingTypeEnum = {
  Moving: 1,
  InOutBed: 2,
  Transfer: 3,
  StandSit: 4,
  Mobility: 5
};

const HandlingLookup = [
  "",
  "Moving / Sitting / Turning in Bed",
  "In / Out of Bed",
  "Transfer Chair - Chair, On / Off Toilet, Bathing",
  "Stand - Sit / Sit- Stand",
  "Mobility"
];
const HandlingLookupShort = ["", "Moving in Bed", "In / Out of Bed", "Transfer Chair", "Stand - Sit", "Mobility"];

export default {
  CapabilityEnum: CapabilityEnum,
  CapabilitySelect: GetSelectLookup(CapabilityEnum, CapabilityLookup),
  CapabilityLookup: CapabilityLookup,
  CapabilityExtra: CapabilityExtra,
  HandlingEnum: HandlingTypeEnum,
  HandlingSelect: GetSelectLookup(HandlingTypeEnum, HandlingLookupShort),
  HandlingLookup: HandlingLookup
};
