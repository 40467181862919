<template>
  <b-field :label="label" horizontal v-if="date" class="labelPaddingFix" :class="fieldClass">
    <b-field> {{ shortFormatDateTime(date) }} by {{ by }} </b-field>
  </b-field>
</template>

<script>
import DatesMixin from "@/mixins/datesMixin";

export default {
  mixins: [DatesMixin],
  props: {
    fieldClass: { type: String },
    date: { type: String, require: true },
    label: { type: String, default: "Recorded" },
    by: { type: String, require: true }
  }
};
</script>
