<template>
  <div>
    <XyeaFormEdit
      :icon="icon"
      :customIcon="customIcon"
      :url="url"
      :entityName="'Resident\'s ' + obName"
      :customTitle="graphOnly ? 'Resident\'s Historical ' + obName : null"
      :form="observation"
      :id="observation.observationId"
      :canSave="canSave"
      @close="$emit('close')"
      @add="add"
      @update="refresh"
      :disabled="readonly"
      :dialog="true"
      :loadOnOpen="loadOnOpen"
      @mapServerData="v => $emit('mapServerData', v)"
      :isWideDialog="isWideDialog"
    >
      <div v-if="graphOnly">
        <ChartDateFilter
          :chartData="chartData"
          :chartOptions="chartOptions"
          @leftClick="moveLeft"
          @rightClick="moveRight"
          :leftDisabled="leftDisabled"
        />
      </div>

      <b-tabs v-else v-model="activeTab">
        <b-tab-item label="Detail">
          <slot />

          <template v-if="!hideNotes">
            <NotesIconField v-model="observation.notes" type="textarea" :readonly="readonly" notesRef="notes" :fieldClass="fieldClass" />
            <RecordedBy :date="observation.obsDate" :by="observation.by" :fieldClass="fieldClass" />
          </template>
        </b-tab-item>
        <b-tab-item label="Historical">
          <ContextTableCard
            :title="'Historical ' + (obNames ? obNames : obName + 's')"
            :icon="icon"
            sortField="obsDate"
            sortOrder="desc"
            :page="-1"
            :total="total"
            :tableData="tableData"
            v-model="selected"
            @contextDefaultClicked="selectOb"
            :isLoading="isLoading"
          >
            <slot name="tableColumns">
              <b-table-column label="Date" field="obsDate" v-slot="props">
                {{ formatDateTimeRecent(props.row.obsDate) }}
              </b-table-column>
              <b-table-column :label="obName" field="value" v-slot="props" header-class="has-thead-right" cellClass="has-text-right">
                {{ tableValue(props.row) }}
              </b-table-column>
              <b-table-column label="By" field="by" v-slot="props">
                {{ props.row.by }}
              </b-table-column>
            </slot>
          </ContextTableCard>
        </b-tab-item>
        <b-tab-item label="Graph">
          <ChartDateFilter
            :chartData="chartData"
            :chartOptions="chartOptions"
            @leftClick="moveLeft"
            @rightClick="moveRight"
            :leftDisabled="leftDisabled"
          />
        </b-tab-item>
      </b-tabs>
      <template v-slot:extraLeftButtons="{ buttonProps }" v-if="canAddNew">
        <div class="modal-card-footer">
          <b-button class="button is-primary" @click="resetForm(buttonProps)">Add New</b-button>
        </div>
      </template>
    </XyeaFormEdit>
  </div>
</template>

<script>
import XyeaFormEdit from "@/components/XyeaFormEdit";
import DatesMixin from "@/mixins/datesMixin";
import ContextTableCard from "@/components/ContextTableCard.vue";
import ContextTableMixin from "@/mixins/contextTableMixin";
import NotesIconField from "@/components/NotesIconField.vue";
import RecordedBy from "@/components/RecordedBy.vue";
import ChartDateFilter from "@/components/ChartDateFilter.vue";

import * as chartConfig from "@/components/Charts/chart.config";

export default {
  mixins: [ContextTableMixin, DatesMixin],
  components: { ContextTableCard, XyeaFormEdit, NotesIconField, RecordedBy, ChartDateFilter },
  props: [
    "observation",
    "icon",
    "customIcon",
    "url",
    "obName",
    "obNames",
    "unit",
    "readonly",
    "canAddNew",
    "loadOnOpen",
    "lookup",
    "fieldClass",
    "customChartData",
    "customChartOptions",
    "separator",
    "number2",
    "graphOnly",
    "hideNotes",
    "isWideDialog"
  ],

  data() {
    return {
      activeTab: this.graphOnly ? 2 : 0,
      chartData: null,
      chartOptions: this.customChartOptions ?? chartConfig.chartOptionsUnitsTimeScale(this.obName, this.unit, this.lookup),
      numPages: 1,
      isLoading: false,
      leftDisabled: false,
      rightDisabled: false
    };
  },
  computed: {
    canSave() {
      return this.activeTab === 0;
    }
  },
  watch: {
    activeTab: {
      immediate: true,
      handler(newTab) {
        if (newTab > 0) {
          if (!this.tableData.length) {
            this.getHistoricalData();
          } else if (newTab === 2 && !this.charthData) {
            this.getGraphData();
          }
        }
      }
    }
  },
  methods: {
    resetForm(formProps) {
      formProps.checkIsDirty(null, null, () => {
        this.$emit("clear");
        this.activeTab = 0;
        formProps.setNotDirty();
      });
    },
    add(newData) {
      this.observation.observationId = newData.observationId;
      this.$emit("refresh", this.observation);
    },
    refresh() {
      this.$emit("refresh", this.observation);
    },
    getHistoricalData() {
      this.isLoading = true;
      const search = {
        residentId: this.observation.residentId,
        numPages: this.numPages
      };
      return this.$http
        .get(this.url + "historical/", { params: search })
        .then(r => {
          this.tableData = r.data;
          if (this.activeTab === 2) this.getGraphData();
        })
        .catch(e => this.$alerts.showErrorAlert(e, "Error loading " + this.entityName))
        .finally(() => (this.isLoading = false));
    },
    getGraphData() {
      const chartData = this.tableData.slice(0).reverse();

      if (chartData) {
        // prettier-ignore
        this.chartData = this.customChartData
          ? this.customChartData(chartData)
          : {
            labels: chartData.map(a => a.obsDate),
            datasets: [
              {
                ...chartConfig.defaultDataSetOptions,
                data: chartData.map(a => a.value),
                label: this.obName
              }
            ]
          };
      }
    },
    tableValue(row) {
      var v = this.lookup ? this.lookup[row.value] : row.value;
      if (this.separator) v += this.separator + row[this.number2];
      v += this.unit ? " " + this.unit : "";
      return v;
    },

    selectOb() {
      this.selected.notDirty = true;
      this.$emit("select", this.selected);
      this.activeTab = 0;
    },
    moveLeft() {
      if (this.isLoading) return;
      const previousRows = this.tableData.length;
      this.numPages++;
      this.getHistoricalData().then(() => {
        // if same number of rows, then there wasn't any more so decrease page number again
        if (this.tableData.length === previousRows) {
          this.numPages--;
          this.leftDisabled = true;
        }
      });
    },
    moveRight() {
      if (this.isLoading) return;
      if (this.numPages > 1) {
        this.numPages--;
        this.getHistoricalData().then(() => (this.leftDisabled = false));
      }
    }
  }
};
</script>
